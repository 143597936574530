import React from 'react';

const Footer = () => (
	<footer id="footer">
		<div className="container">			
			<p className="copyright"><small>&copy; 2020 YOJIRO IMASAKA</small></p>
		</div>
	</footer>
);

export default Footer;